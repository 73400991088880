import dayjs from 'dayjs';

import adjustmentsLogo from '../../../assets/svgs/adjustment.svg';
import aliPayLogo from '../../../assets/svgs/ali-pay.svg';
import amexLogo from '../../../assets/svgs/amex2.svg';
import cardLogo from '../../../assets/svgs/card.svg';
import cashLogo from '../../../assets/svgs/cash.svg';
import customPaymentsLogo from '../../../assets/svgs/custom-payments.svg';
import grabPayLogo from '../../../assets/svgs/grab-pay.svg';
import masterCardLogo from '../../../assets/svgs/master.svg';
import payNowLogo from '../../../assets/svgs/pay-now.svg';
import unionPayLogo from '../../../assets/svgs/union-pay.svg';
import visaLogo from '../../../assets/svgs/visa.svg';
import walletLogo from '../../../assets/svgs/wallet.svg';
import weChatPayLogo from '../../../assets/svgs/we-chat-pay.svg';
import jcbLogo from '../../../assets/svgs/jcb.svg';
import {
  APP_COLUMN_TYPES,
  APP_TABLE_ALIGNMENT,
  IAppTableColumn,
  IAppTableRow,
} from '../../../components/AppTable/types/table.types';
import { ORDER_STATUS, PAYMENT_METHOD_TYPES } from '../../../constants';
import { Colors } from '../../../theme/colors';
import { PaymentTypes } from '../constants';
import {
  IReceiptsTable,
  ReceiptsResponseDTO,
  SELECTED_TAB,
} from '../types/receipts.types';
import {
  IDailyStripeSummaryResponseDTO,
  IDailyStripeSummaryTable,
  IOrderCountSummaryTable,
  IReconciliationSummaryResponseDTO,
  IReconciliationSummaryTable,
  ISalesByMenuItemSummaryTable,
  OrderCountSummaryResponseDTO,
  SalesByMenuItemSummaryDTO,
} from '../types/reports.types';
import {
  ISalesSummaryTable,
  SalesSummaryResponseDTO,
} from '../types/sales-summary.types';
import {
  IShiftSummaryResponseDTO,
  IShiftSummaryTable,
} from '../types/shift-summary.types';

export const getCardTypeImage = (cardType: string) => {
  switch (cardType) {
    case PaymentTypes.CARD_LOCAL:
      return cardLogo;
    case PaymentTypes.CARD_INTERNATIONAL:
      return cardLogo;
    case PaymentTypes.VISA:
      return visaLogo;
    case PaymentTypes.MASTERCARD:
    case PaymentTypes.MC:
      return masterCardLogo;
    case PaymentTypes.AMEX:
      return amexLogo;
    case PaymentTypes.UNION_PAY:
    case PaymentTypes.CUP:
      return unionPayLogo;
    case PaymentTypes.JCB:
      return jcbLogo;
    case PaymentTypes.WALLETS:
      return walletLogo;
    case PaymentTypes.ALI_PAY:
      return aliPayLogo;
    case PaymentTypes.GRAB_PAY:
      return grabPayLogo;
    case PaymentTypes.PAY_NOW:
      return payNowLogo;
    case PaymentTypes.WE_CHAT_PAY:
      return weChatPayLogo;
    case PaymentTypes.CASH:
      return cashLogo;
    case PaymentTypes.ADJUSTMENTS:
      return adjustmentsLogo;
    case PaymentTypes.OTHER_PAYMENTS:
      return customPaymentsLogo;
  }
};

const getPaymentTypeIconWidth = (
  paymentType: IReconciliationSummaryResponseDTO['paymentType'],
) => {
  if (
    paymentType === PaymentTypes.VISA ||
    paymentType === PaymentTypes.MASTERCARD ||
    paymentType === PaymentTypes.MC ||
    paymentType === PaymentTypes.AMEX ||
    paymentType === PaymentTypes.UNION_PAY ||
    paymentType === PaymentTypes.CUP ||
    paymentType === PaymentTypes.JCB ||
    paymentType === PaymentTypes.ALI_PAY ||
    paymentType === PaymentTypes.GRAB_PAY ||
    paymentType === PaymentTypes.PAY_NOW ||
    paymentType === PaymentTypes.WE_CHAT_PAY
  ) {
    return 40;
  } else {
    return 16;
  }
};

export const getFormattedReconciliationSummary = (
  payment: IReconciliationSummaryResponseDTO,
) => {
  const row: IAppTableRow<IReconciliationSummaryTable> = {
    key: payment.paymentType,
    paymentType: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value:
          payment.paymentType === PaymentTypes.VISA ||
          payment.paymentType === PaymentTypes.MASTERCARD ||
          payment.paymentType === PaymentTypes.AMEX ||
          payment.paymentType === PaymentTypes.UNION_PAY ||
          payment.paymentType === PaymentTypes.ALI_PAY ||
          payment.paymentType === PaymentTypes.GRAB_PAY ||
          payment.paymentType === PaymentTypes.PAY_NOW ||
          payment.paymentType === PaymentTypes.WE_CHAT_PAY ||
          payment.paymentType === PaymentTypes.JCB
            ? ''
            : payment.paymentType,
        color:
          payment.paymentType === PaymentTypes.CARD_LOCAL ||
          payment.paymentType === PaymentTypes.CARD_INTERNATIONAL ||
          payment.paymentType === PaymentTypes.WALLETS ||
          payment.paymentType === PaymentTypes.CASH ||
          payment.paymentType === PaymentTypes.ADJUSTMENTS
            ? Colors.fontColorSecondary
            : Colors.fontColorPrimary,
        fontWeight:
          payment.paymentType === PaymentTypes.GRAND_TOTAL ? 700 : 600,
        icon: getCardTypeImage(payment.paymentType),
        iconWidth: getPaymentTypeIconWidth(payment.paymentType),
      },
    },
    collections: {
      type: APP_COLUMN_TYPES.CURRENCY,
      data: {
        value: payment.collections,
        currency: '',
        handleNullAsDash:
          payment.paymentType === PaymentTypes.ADJUSTMENTS ? true : false,
        ignoreFormatting:
          payment.paymentType === PaymentTypes.ADJUSTMENTS ||
          payment.paymentType === PaymentTypes.REFUNDS ||
          payment.paymentType === ''
            ? true
            : false,
        fontWeight: 600,
      },
    },
    counts: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: payment.counts,
        fontWeight: 400,
      },
    },
    trxPercentageFee: {
      type: APP_COLUMN_TYPES.CURRENCY,
      data: {
        value: payment.trxPercentageFee,
        currency: '',
        handleNullAsDash:
          payment.paymentType === PaymentTypes.OTHER_PAYMENTS ||
          payment.paymentType === PaymentTypes.CASH ||
          payment.paymentType === '' ||
          !Object.values(PaymentTypes).includes(
            payment.paymentType as PaymentTypes,
          )
            ? true
            : false,
        ignoreFormatting:
          payment.paymentType === PaymentTypes.OTHER_PAYMENTS ||
          payment.paymentType === PaymentTypes.CASH ||
          payment.paymentType === '' ||
          !Object.values(PaymentTypes).includes(
            payment.paymentType as PaymentTypes,
          )
            ? true
            : false,
        fontWeight: 400,
      },
    },
    trxFixedFee: {
      type: APP_COLUMN_TYPES.CURRENCY,
      data: {
        value: payment.trxFixedFee,
        currency: '',
        handleNullAsDash:
          payment.paymentType === PaymentTypes.OTHER_PAYMENTS ||
          payment.paymentType === PaymentTypes.CASH ||
          payment.paymentType === '' ||
          !Object.values(PaymentTypes).includes(
            payment.paymentType as PaymentTypes,
          )
            ? true
            : false,
        ignoreFormatting:
          payment.paymentType === PaymentTypes.OTHER_PAYMENTS ||
          payment.paymentType === PaymentTypes.CASH ||
          payment.paymentType === '' ||
          !Object.values(PaymentTypes).includes(
            payment.paymentType as PaymentTypes,
          )
            ? true
            : false,
        fontWeight: 400,
      },
    },
    trxGrandTotal: {
      type: APP_COLUMN_TYPES.CURRENCY,
      data: {
        value: payment.trxGrandTotal,
        currency: '',
        handleNullAsDash:
          payment.paymentType === PaymentTypes.OTHER_PAYMENTS ||
          payment.paymentType === PaymentTypes.CASH ||
          payment.paymentType === '' ||
          !Object.values(PaymentTypes).includes(
            payment.paymentType as PaymentTypes,
          )
            ? true
            : false,
        ignoreFormatting:
          payment.paymentType === PaymentTypes.OTHER_PAYMENTS ||
          payment.paymentType === PaymentTypes.CASH ||
          payment.paymentType === '' ||
          !Object.values(PaymentTypes).includes(
            payment.paymentType as PaymentTypes,
          )
            ? true
            : false,
        fontWeight: 400,
      },
    },
    refunds: {
      type: APP_COLUMN_TYPES.CURRENCY,
      data: {
        value: `-${payment.refunds}`,
        currency: '',
        fontWeight: 400,
      },
    },
    balance: {
      type: APP_COLUMN_TYPES.CURRENCY,
      data: {
        value: payment.balance,
        currency: '',
        fontWeight:
          payment.paymentType === PaymentTypes.CARD_LOCAL ||
          payment.paymentType === PaymentTypes.CARD_INTERNATIONAL ||
          payment.paymentType === PaymentTypes.WALLETS ||
          payment.paymentType === PaymentTypes.CASH ||
          payment.paymentType === PaymentTypes.ADJUSTMENTS ||
          payment.paymentType === PaymentTypes.GRAND_TOTAL
            ? 700
            : 400,
        fontSize:
          payment.paymentType === PaymentTypes.CARD_LOCAL ||
          payment.paymentType === PaymentTypes.CARD_INTERNATIONAL ||
          payment.paymentType === PaymentTypes.WALLETS ||
          payment.paymentType === PaymentTypes.CASH ||
          payment.paymentType === PaymentTypes.ADJUSTMENTS ||
          payment.paymentType === PaymentTypes.GRAND_TOTAL
            ? 14
            : 13,
      },
    },
    subRows: payment.subRows?.map(getFormattedReconciliationSummary) ?? [],
    actions: [],
  };

  return row;
};

export const getFormattedReceipts = (
  receipt: ReceiptsResponseDTO,
  currency?: string,
) => {
  const paymentTypeIcons = getPaymentTypeImages(receipt.paymentMethods);
  const row: IAppTableRow<IReceiptsTable> = {
    key: receipt.orderId.toString(),
    placedAtTime: {
      type: APP_COLUMN_TYPES.DATE,
      data: {
        value: new Date(receipt.completedAt).toISOString(),
        disableLocalTime: true,
      },
    },
    receipt: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: receipt.ticketNumber,
      },
    },
    ticket: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: receipt.ticketName ? receipt.ticketName : '-',
      },
    },
    employee: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: receipt.createdByName,
      },
    },
    status: {
      type: APP_COLUMN_TYPES.CHIP,
      data: {
        value: getStatusText(receipt.orderStatus),
        ...getStatusChipData(receipt.orderStatus),
      },
    },
    paymentType: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: receipt.paymentMethods ? '' : '-',
        icons: paymentTypeIcons,
      },
    },
    total: {
      type: APP_COLUMN_TYPES.CURRENCY,
      data: {
        value: receipt.netTotal,
        currency: `${currency}`,
      },
    },
    subRows: [],
    actions: [],
  };
  return row;
};

const prepareForCSV = (value: string) => {
  const updatedValue = value
    .split(',')
    .map((method) => {
      if (method.trim() === PAYMENT_METHOD_TYPES.STRIPE_TERMINAL) {
        return PAYMENT_METHOD_TYPES.CARD_ONLINE;
      } else {
        return method.trim();
      }
    })
    .join(',');

  return `"${updatedValue.replace(/#/g, '').replace(/"/g, '""')}"`;
};

export const getFormattedReceiptsExport = (
  receipt: ReceiptsResponseDTO,
  currency?: string,
) => {
  const row: IAppTableRow<IReceiptsTable> = {
    key: receipt.orderId.toString(),
    placedAtTime: {
      type: APP_COLUMN_TYPES.DATE,
      data: {
        value: new Date(receipt.completedAt).toISOString(),
        disableLocalTime: true,
      },
    },
    receipt: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: `="${receipt.ticketNumber}"`,
      },
    },
    ticket: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: receipt.ticketName ? prepareForCSV(receipt.ticketName) : '-',
      },
    },
    employee: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: receipt.createdByName,
      },
    },
    status: {
      type: APP_COLUMN_TYPES.CHIP,
      data: {
        value: getStatusText(receipt.orderStatus),
        ...getStatusChipData(receipt.orderStatus),
      },
    },
    paymentType: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: prepareForCSV(receipt.paymentMethods),
      },
    },
    total: {
      type: APP_COLUMN_TYPES.CURRENCY,
      data: {
        value: receipt.netTotal,
        currency: `${currency}`,
      },
    },
    subRows: [],
    actions: [],
  };
  return row;
};

export const getPaymentTypes = (paymentType: string) => {
  switch (paymentType) {
    case PaymentTypes.VISA:
      return 'Visa';
    case PaymentTypes.MASTERCARD:
      return 'Master Card';
    case PaymentTypes.AMEX:
      return 'Amex';
    case PaymentTypes.UNION_PAY:
      return 'Union Pay';
    case PaymentTypes.ALI_PAY:
      return 'Ali Pay';
    case PaymentTypes.GRAB_PAY:
      return 'Grab Pay';
    case PaymentTypes.PAY_NOW:
      return 'Pay Now';
    default:
      return paymentType;
  }
};

const getPaymentTypeImages = (paymentMethods: string): string[] => {
  return paymentMethods
    .split(',')
    .map((method) => {
      switch (method.trim()) {
        case 'Stripe Terminal':
          return cardLogo;
        case 'Cash':
          return cashLogo;
        case 'custom':
          return customPaymentsLogo;
        default:
          return '';
      }
    })
    .filter((icon) => icon);
};

export const getFormattedReconciliationSummaryExport = (
  payment: IReconciliationSummaryResponseDTO,
) => {
  const row: IAppTableRow<IReconciliationSummaryTable> = {
    key: payment.paymentType,
    paymentType: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: getPaymentTypes(payment.paymentType),
      },
    },
    collections: {
      type: APP_COLUMN_TYPES.CURRENCY,
      data: {
        value: payment.collections,
        currency: '',
      },
    },
    counts: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: payment.counts,
      },
    },
    trxPercentageFee: {
      type: APP_COLUMN_TYPES.CURRENCY,
      data: {
        value: payment.trxPercentageFee,
        currency: '',
      },
    },
    trxFixedFee: {
      type: APP_COLUMN_TYPES.CURRENCY,
      data: {
        value: payment.trxFixedFee,
        currency: '',
      },
    },
    trxGrandTotal: {
      type: APP_COLUMN_TYPES.CURRENCY,
      data: {
        value: payment.trxGrandTotal,
        currency: '',
      },
    },
    refunds: {
      type: APP_COLUMN_TYPES.CURRENCY,
      data: {
        value: `-${payment.refunds}`,
        currency: '',
      },
    },
    balance: {
      type: APP_COLUMN_TYPES.CURRENCY,
      data: {
        value: payment.balance,
        currency: '',
      },
    },
    subRows: [],
    actions: [],
  };
  return row;
};

export const getFormattedOrderAnalytics = (
  item: OrderCountSummaryResponseDTO,
) => {
  const row: IAppTableRow<IOrderCountSummaryTable> = {
    key: item.orderDate,
    orderDate: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: item.orderDate,
      },
    },
    dineInCounts: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: item.dineInCounts,
      },
    },
    pickUpCounts: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: item.pickUpCounts,
      },
    },
    deliveryCounts: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: item.deliveryCounts,
      },
    },
    otherPaymentsCounts: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: item.otherPaymentsCounts,
      },
    },
    totalOrders: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: item.totalOrders,
      },
    },
    subRows: [],
    actions: [],
  };
  return row;
};

const getStatusText = (status: string): string => {
  switch (status) {
    case ORDER_STATUS.PENDING:
      return 'Pending';
    case ORDER_STATUS.COMPLETED:
      return 'Succeeded';
    case ORDER_STATUS.REFUNDED:
      return 'Refunded';
    case ORDER_STATUS.PARTIALLY_REFUNDED:
      return 'Partially Refunded';
    case ORDER_STATUS.DELETED:
      return 'Voided';
    default:
      return 'Unknown';
  }
};

const getStatusChipData = (
  value: string,
): {
  color?: string;
  backgroundColor?: string;
} => {
  switch (value) {
    case ORDER_STATUS.COMPLETED:
      return {
        backgroundColor: Colors.chipSuccessBackground,
        color: Colors.chipSuccessText,
      };

    case ORDER_STATUS.REFUNDED:
    case ORDER_STATUS.PARTIALLY_REFUNDED:
    case ORDER_STATUS.DELETED:
      return {
        backgroundColor: Colors.chipFailedBackground,
        color: Colors.chipFailedText,
      };

    default:
      return {
        backgroundColor: Colors.chipDefaultBackground,
        color: Colors.chipDefaultText,
      };
  }
};

export const getFormattedSalesByMenuItemSummary = (
  item: SalesByMenuItemSummaryDTO,
  dataCurrency?: string,
) => {
  const row: IAppTableRow<ISalesByMenuItemSummaryTable> = {
    key: item.menuItem,
    menuItem: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: item.menuItem,
      },
    },
    category: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: item.category,
      },
    },
    qtySold: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: item.qtySold,
      },
    },
    price: {
      type: APP_COLUMN_TYPES.CURRENCY,
      data: {
        value: item.price,
        currency: dataCurrency ?? 'SGD ',
      },
    },
    sales: {
      type: APP_COLUMN_TYPES.CURRENCY,
      data: {
        value: item.sales,
        currency: dataCurrency ?? 'SGD ',
      },
    },
    subRows: [],
    actions: [],
  };

  return row;
};

export const getFormattedShiftSummary = (
  shiftSummary: IShiftSummaryResponseDTO,
  currency?: string,
) => {
  const row: IAppTableRow<IShiftSummaryTable> = {
    key: String(shiftSummary.shiftId),
    createdAt: {
      type: APP_COLUMN_TYPES.DATE,
      data: {
        value: shiftSummary.createdAt,
        disableLocalTime: true,
      },
    },
    shiftNumber: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: shiftSummary.shiftNumber.padStart(3, '0'),
      },
    },
    openedByName: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: shiftSummary.openedByName ?? '-',
      },
    },
    closedByName: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: shiftSummary.closedByName ?? '-',
      },
    },
    totalGross: {
      type: APP_COLUMN_TYPES.CURRENCY,
      data: {
        value: String(shiftSummary.totalGross),
        currency: `${currency} `,
      },
    },
    totalDiscount: {
      type: APP_COLUMN_TYPES.CURRENCY,
      data: {
        value: shiftSummary.totalDiscount?.toString(),
        currency: `-${currency} `,
      },
    },
    totalRefundAmount: {
      type: APP_COLUMN_TYPES.CURRENCY,
      data: {
        value: String(shiftSummary.totalRefundAmount),
        currency: `-${currency} `,
      },
    },
    netTotal: {
      type: APP_COLUMN_TYPES.CURRENCY,
      data: {
        value: String(shiftSummary.netTotal),
        currency: `${currency} `,
      },
    },
    subRows: [],
    actions: [],
  };

  return row;
};

export interface OriginalData {
  hour_placed: string;
  date: string;
  totalEarnings: number;
}

interface FormattedData {
  id: string;
  data: { x: string; y: number }[];
}

export const formatHourlySalesData = (
  originalData: OriginalData[],
): FormattedData[] => {
  const allHours = Array.from(
    new Set(originalData.map((entry) => entry.hour_placed)),
  );

  const sortHours = () => {
    return allHours.sort((a, b) => {
      const [aHour, aPeriod] = a.split(' ');
      const [bHour, bPeriod] = b.split(' ');

      const aNum =
        (aPeriod === 'PM'
          ? (parseInt(aHour, 10) % 12) + 12
          : parseInt(aHour, 10)) % 24;
      const bNum =
        (bPeriod === 'PM'
          ? (parseInt(bHour, 10) % 12) + 12
          : parseInt(bHour, 10)) % 24;

      if (aNum !== bNum) {
        return aNum - bNum;
      }

      // If hours are the same, prioritize 'AM' over 'PM'
      if (aPeriod === 'AM' && bPeriod === 'PM') {
        return -1;
      } else if (aPeriod === 'PM' && bPeriod === 'AM') {
        return 1;
      }

      return 0;
    });
  };

  const sortedHours = sortHours();

  const uniqueDates = Array.from(
    new Set(originalData.map((entry) => entry.date)),
  ).sort((a, b) => {
    const dateA = dayjs(a, 'DD-MM-YYYY');
    const dateB = dayjs(b, 'DD-MM-YYYY');

    const dateBIsAfterDateA = dateB.isAfter(dateA) ? 1 : 0;

    return dateB.isBefore(dateA) ? -1 : dateBIsAfterDateA;
  });

  const formattedData: FormattedData[] = uniqueDates.map((date) => {
    const dateEntries = originalData.filter((entry) => entry.date === date);

    return {
      id: date,
      data: sortedHours.map((hour) => {
        const dateEntry = dateEntries.find(
          (entry) => entry.hour_placed === hour,
        );
        const totalEarnings = dateEntry ? dateEntry.totalEarnings : 0;

        return {
          x: hour,
          y: totalEarnings,
        };
      }),
    };
  });

  return formattedData;
};

export const getFormattedSalesSummary = (
  salesSummary: SalesSummaryResponseDTO,
  currency?: string,
) => {
  const { date, data } = salesSummary;
  const { grossSales, discounts, refunds, netSales } = data;

  const row: IAppTableRow<ISalesSummaryTable> = {
    key: salesSummary.date,
    date: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: date,
      },
    },
    grossSales: {
      type: APP_COLUMN_TYPES.CURRENCY,
      data: {
        value: String(grossSales),
        currency: `${currency} `,
      },
    },
    discounts: {
      type: APP_COLUMN_TYPES.CURRENCY,
      data: {
        value: String(discounts),
        currency: `- ${currency} `,
      },
    },
    refunds: {
      type: APP_COLUMN_TYPES.CURRENCY,
      data: {
        value: String(refunds),
        currency: `- ${currency} `,
      },
    },
    netSales: {
      type: APP_COLUMN_TYPES.CURRENCY,
      data: {
        value: String(netSales),
        currency: `${currency} `,
      },
    },
    subRows: [],
    actions: [],
  };
  return row;
};

export const getFormattedSalesSummaryExport = (
  salesSummary: SalesSummaryResponseDTO,
  currency?: string,
) => {
  const { date, data } = salesSummary;
  const { grossSales, discounts, refunds, netSales } = data;

  const formattedDate = `"${date.replace(/"/g, '""')}"`;

  const row: IAppTableRow<ISalesSummaryTable> = {
    key: salesSummary.date,
    date: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: formattedDate,
      },
    },
    grossSales: {
      type: APP_COLUMN_TYPES.CURRENCY,
      data: {
        value: String(grossSales),
        currency: `${currency} `,
      },
    },
    discounts: {
      type: APP_COLUMN_TYPES.CURRENCY,
      data: {
        value: String(discounts),
        currency: `${currency} `,
      },
    },
    refunds: {
      type: APP_COLUMN_TYPES.CURRENCY,
      data: {
        value: String(refunds),
        currency: `${currency} `,
      },
    },
    netSales: {
      type: APP_COLUMN_TYPES.CURRENCY,
      data: {
        value: String(netSales),
        currency: `${currency} `,
      },
    },
    subRows: [],
    actions: [],
  };
  return row;
};

export const emptyStateData = [
  { date: 'Tue, 2 Apr', grossSales: 1000 },
  { date: 'Wed, 3 Apr', grossSales: 2000 },
  { date: 'Thu, 4 Apr', grossSales: 200 },
  { date: 'Fri, 5 Apr', grossSales: 5000 },
  { date: 'Sat, 6 Apr', grossSales: 4000 },
  { date: 'Sun, 7 Apr', grossSales: 2000 },
  { date: 'Mon, 8 Apr', grossSales: 1000 },
  { date: 'Tue, 9 Apr', grossSales: 100 },
  { date: 'Wed, 10 Apr', grossSales: 10 },
];

export const getFormattedHourlySales = (item: ProcessedData) => {
  const row: IAppTableRow<ProcessedData> = {
    key: item.date,
    date: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: item.date,
      },
    },
    hour: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: item.hour,
      },
    },
    subRows: [],
    actions: [],
  };
  return row;
};

export const getFormattedHourlySalesColumns = (
  data: OriginalData[],
): IAppTableColumn[] => {
  const uniqueHours = Array.from(new Set(data.map((item) => item.hour_placed)));

  // Custom sorting function for time format like '11AM', '12PM', etc.
  uniqueHours.sort((a, b) => {
    const [hourA, periodA] = a.split(' ');
    const [hourB, periodB] = b.split(' ');

    const timeA = getTimeValue(hourA, periodA);
    const timeB = getTimeValue(hourB, periodB);

    return timeA - timeB;
  });

  const mappedData: IAppTableColumn[] = uniqueHours.map((hour) => ({
    accessorKey: hour,
    header: hour,
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: '',
    visible: true,
  }));

  mappedData.unshift({
    accessorKey: 'date',
    header: 'DATE',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: '',
    visible: true,
  });

  return mappedData;
};

export interface ProcessedData {
  date: string;
  [hour: string]: number | string;
}

export const fillMissingHours = (data: OriginalData[]): ProcessedData[] => {
  const result: ProcessedData[] = [];

  // Get a unique set of hours from all data
  const uniqueHours = Array.from(
    new Set(data.map((item) => item.hour_placed)),
  ).sort((a, b) => {
    // Custom sorting for AM and PM
    const [hourA, periodA] = a.split(' ');
    const [hourB, periodB] = b.split(' ');

    if (periodA === periodB) {
      const timeA = getTimeValue(hourA, periodA);
      const timeB = getTimeValue(hourB, periodB);
      return timeA - timeB;
    } else {
      return periodA === 'AM' ? -1 : 1;
    }
  });

  data.forEach((item) => {
    const { hour_placed, date, totalEarnings } = item;

    const existingEntry = result.find((entry) => entry.date === date);

    if (!existingEntry) {
      // If the date doesn't exist in the result array, create a new entry
      const newEntry: ProcessedData = {
        date,
        ...Object.fromEntries(uniqueHours.map((hour) => [hour, 0])),
        [hour_placed]: totalEarnings,
      };
      result.unshift(newEntry); // Add new entry to the beginning of the array
    } else {
      // If the date exists, update the existing entry
      existingEntry[hour_placed] = totalEarnings;
    }
  });

  return result;
};

function getTimeValue(hour: string, period: string) {
  if (hour === '12') {
    return 0;
  } else if (period === 'PM') {
    return parseInt(hour) + 12;
  } else {
    return parseInt(hour);
  }
}

export const extractTime = (dateTimeString: string): string => {
  const dateTime = new Date(dateTimeString);
  const hours = String(dateTime.getHours()).padStart(2, '0');
  const minutes = String(dateTime.getMinutes()).padStart(2, '0');
  return `${hours}:${minutes}`;
};

export const getFormattedDailyStripeSummary = (
  stripeSummary: IDailyStripeSummaryResponseDTO,
) => {
  const row: IAppTableRow<IDailyStripeSummaryTable> = {
    key: stripeSummary.date,
    date: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: getPaymentTypes(stripeSummary.date),
      },
    },
    count: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: getPaymentTypes(stripeSummary.count),
      },
    },
    collections: {
      type: APP_COLUMN_TYPES.CURRENCY,
      data: {
        value: stripeSummary.collections,
        currency: '',
      },
    },
    transactionFees: {
      type: APP_COLUMN_TYPES.CURRENCY,
      data: {
        value: stripeSummary.transactionFees,
        currency: '',
      },
    },
    refunds: {
      type: APP_COLUMN_TYPES.CURRENCY,
      data: {
        value: stripeSummary.refunds,
        currency: '',
      },
    },
    adjustments: {
      type: APP_COLUMN_TYPES.CURRENCY,
      data: {
        value: stripeSummary.adjustments,
        currency: '',
      },
    },
    payable: {
      type: APP_COLUMN_TYPES.CURRENCY,
      data: {
        value: stripeSummary.payable,
        currency: '',
      },
    },
    subRows: [],
    actions: [],
  };
  return row;
};

export const getFormattedDailyAdyenSummary = (
  adyenSummary: IDailyStripeSummaryResponseDTO,
) => {
  const row: IAppTableRow<IDailyStripeSummaryTable> = {
    key: adyenSummary.date,
    date: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: getPaymentTypes(adyenSummary.date),
      },
    },
    count: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: getPaymentTypes(adyenSummary.count),
      },
    },
    collections: {
      type: APP_COLUMN_TYPES.CURRENCY,
      data: {
        value: adyenSummary.collections,
        currency: '',
      },
    },
    transactionFees: {
      type: APP_COLUMN_TYPES.CURRENCY,
      data: {
        value: adyenSummary.transactionFees,
        currency: '',
      },
    },
    refunds: {
      type: APP_COLUMN_TYPES.CURRENCY,
      data: {
        value: adyenSummary.refunds,
        currency: '',
      },
    },
    adjustments: {
      type: APP_COLUMN_TYPES.CURRENCY,
      data: {
        value: adyenSummary.adjustments,
        currency: '',
      },
    },
    payable: {
      type: APP_COLUMN_TYPES.CURRENCY,
      data: {
        value: adyenSummary.payable,
        currency: '',
      },
    },
    subRows: [],
    actions: [],
  };
  return row;
};

export const getStatusValue = (statusTab: SELECTED_TAB) => {
  switch (statusTab) {
    case SELECTED_TAB.ALL:
      return [
        ORDER_STATUS.COMPLETED,
        ORDER_STATUS.DELETED,
        ORDER_STATUS.REFUNDED,
        ORDER_STATUS.PARTIALLY_REFUNDED,
      ].join(', ');
    case SELECTED_TAB.COMPLETED:
      return ORDER_STATUS.COMPLETED;
    case SELECTED_TAB.REFUNDED:
      return [ORDER_STATUS.REFUNDED, ORDER_STATUS.PARTIALLY_REFUNDED].join(
        ', ',
      );
    case SELECTED_TAB.DELETED:
      return ORDER_STATUS.DELETED;
    default:
      return;
  }
};
