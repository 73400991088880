import dayjs from 'dayjs';

import { decimalCalculations } from '../../../utils';
import {
  FILTER_TYPES,
  SalesSummaryFilterType,
} from '../types/sales-summary.types';
import { RestaurantResponseDTO } from '../../auth/types/auth.types';
import { RESTAURANT_PAYMENT_PROVIDER } from '../../../constants';
import { RECONCILIATION_VIEW } from '../types/reports.types';

export const determineFilterType = (
  startDate: string | undefined,
  endDate: string | undefined,
  currentFilter: SalesSummaryFilterType,
): SalesSummaryFilterType => {
  if (!startDate || !endDate) {
    return FILTER_TYPES.MONTHLY;
  }

  const isSameDay = dayjs(startDate).isSame(dayjs(endDate), 'day');

  const dayDifference = dayjs(endDate).diff(dayjs(startDate), 'day');
  const weekDifference = dayjs(endDate).diff(dayjs(startDate), 'week');
  const yearDifference = dayjs(endDate).diff(dayjs(startDate), 'year');
  let newFilterType = currentFilter;

  if (currentFilter === FILTER_TYPES.HOURLY && !isSameDay) {
    newFilterType =
      dayDifference <= 60 ? FILTER_TYPES.DAILY : FILTER_TYPES.WEEKLY;
  } else if (currentFilter === FILTER_TYPES.DAILY) {
    if (isSameDay) {
      newFilterType = FILTER_TYPES.HOURLY;
    } else if (dayDifference > 60) {
      newFilterType = FILTER_TYPES.WEEKLY;
    }
  } else if (
    currentFilter === FILTER_TYPES.WEEKLY ||
    currentFilter === FILTER_TYPES.DAY_OF_THE_WEEK
  ) {
    if (weekDifference < 1 && dayDifference > 1) {
      newFilterType = FILTER_TYPES.DAILY;
    } else if (isSameDay) {
      newFilterType = FILTER_TYPES.HOURLY;
    }
  } else if (currentFilter === FILTER_TYPES.MONTHLY) {
    if (dayDifference < 30 && dayDifference > 1) {
      newFilterType = FILTER_TYPES.DAILY;
    } else if (isSameDay) {
      newFilterType = FILTER_TYPES.HOURLY;
    }
  } else if (currentFilter === FILTER_TYPES.YEARLY) {
    if (yearDifference < 1 && dayDifference > 1) {
      newFilterType = FILTER_TYPES.DAILY;
    } else if (isSameDay) {
      newFilterType = FILTER_TYPES.HOURLY;
    }
  }

  return newFilterType;
};

export const formatValue = (value: number, posCurrencyCode: string): string => {
  if (value >= 1000000) {
    return `${decimalCalculations(value / 1000).toMoney(posCurrencyCode)} k`;
  } else {
    return decimalCalculations(value).toMoney(posCurrencyCode);
  }
};

export const getReconciliationSummaryViewType = ({
  restaurant,
  isLoading = false,
}: {
  restaurant?: RestaurantResponseDTO;
  isLoading?: boolean;
}): RECONCILIATION_VIEW | undefined => {
  if (isLoading) {
    return RECONCILIATION_VIEW.LOADING;
  }

  if (restaurant?.paymentProvider === RESTAURANT_PAYMENT_PROVIDER.ADYEN) {
    return RECONCILIATION_VIEW.ADYEN_PAYMENTS;
  }

  return undefined;
};
