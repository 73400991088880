import Box from '@mui/material/Box';
import { useCallback, useEffect, useMemo } from 'react';

import AppTable from '../../../components/AppTable';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { updatePaymentsReportLastUpdated } from '../../../redux/AppSlice';
import { selectApp } from '../../../redux/selectors/appSelectors';
import { selectAuth } from '../../../redux/selectors/authSelectors';
import ReportsEmptyTable from '../components/ReportsEmptyTable';
import { fetchAdyenReconciliationSummaryDetails } from '../redux/adyenReconciliationSummarySlice';
import { getFormattedReconciliationSummary } from '../utils/formatting.utils';
import { selectAdyenReconciliationSummary } from '../../../redux/selectors/adyenReconciliationSummarySelectors';

const AdyenReconciliationSummaryView = () => {
  const dispatch = useAppDispatch();

  const { reconciliationSummaryData, columns, refreshing, lastUpdated } =
    useAppSelector(selectAdyenReconciliationSummary);

  const { reportStartDate, reportEndDate } = useAppSelector(selectApp);

  const { restaurant } = useAppSelector(selectAuth);

  const getData = useCallback(async () => {
    await dispatch(
      fetchAdyenReconciliationSummaryDetails({
        date_from: reportStartDate ?? undefined,
        date_to: reportEndDate ?? undefined,
        offset: restaurant?.timeZoneValue,
      }),
    );
  }, [dispatch, reportEndDate, reportStartDate, restaurant?.timeZoneValue]);

  useEffect(() => {
    dispatch(updatePaymentsReportLastUpdated(lastUpdated));
  }, [dispatch, lastUpdated]);

  useEffect(() => {
    getData();
  }, [dispatch, getData]);

  const formattedData = useMemo(() => {
    console.log('Raw Data before formatting:', reconciliationSummaryData);
    return reconciliationSummaryData.map(getFormattedReconciliationSummary);
  }, [reconciliationSummaryData]);

  console.log('Formatted Data for Table:', formattedData);

  return (
    <Box
      sx={{
        mb: 5,
      }}
      data-testid="Adyen Payments Reconciliation Summary">
      <AppTable
        columns={columns}
        rows={formattedData}
        refreshing={refreshing}
        emptyComponent={<ReportsEmptyTable title={'No records found'} />}
        enablePagination={false}
        enableExpanding={true}
        enableExpandAll={true}
      />
    </Box>
  );
};

export default AdyenReconciliationSummaryView;
