import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Outlet, useNavigate } from 'react-router-dom';

import { Colors } from '../theme/colors';

type ReportsLayoutProps = {
  title?: string;
  titleVariant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  HeaderComponent?: () => JSX.Element;
  FilterComponent?: () => JSX.Element;
  ContentComponent?: () => JSX.Element | null | undefined;
  FooterComponent?: () => JSX.Element;
  HorizontalTabs?: () => JSX.Element | null | undefined;
};

const ReportsLayout = ({
  title,
  titleVariant = 'h6',
  HeaderComponent,
  FilterComponent,
  ContentComponent,
  FooterComponent,
  HorizontalTabs,
}: ReportsLayoutProps) => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        boxSizing: 'border-box',
        width: '100%',
      }}>
      {title ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            my: 2,
          }}>
          <IconButton
            sx={{ mt: -0.5 }}
            onClick={() => {
              navigate('/reports');
            }}>
            <ArrowBackIcon />
          </IconButton>
          <Typography
            variant={titleVariant}
            sx={{ ml: 2, color: Colors.fontColorPrimary }}>
            {title}
          </Typography>
        </Box>
      ) : null}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
        }}>
        {HorizontalTabs ? <HorizontalTabs /> : null}
      </Box>

      <Outlet />

      {HeaderComponent ? <HeaderComponent /> : null}

      {FilterComponent ? <FilterComponent /> : null}

      {ContentComponent ? <ContentComponent /> : null}

      {FooterComponent ? <FooterComponent /> : null}
    </Box>
  );
};

export default ReportsLayout;
