import ReportsLayout from '../../../hocs/ReportsLayout';
import { useAppSelector } from '../../../hooks';
import { selectAuth } from '../../../redux/selectors/authSelectors';
import AdyenReconciliationSummaryFilters from '../components/AdyenReconciliationSummaryFilters';
import AdyenReconciliationSummaryHeader from '../components/AdyenReconciliationSummaryHeader';
import PaymentsReportLastUpdateAtFooter from '../components/PaymentsReportLastUpdateAtFooter';
import ReconciliationSummaryFilters from '../components/ReconciliationSummaryFilters';
import ReconciliationSummaryHeader from '../components/ReconciliationSummaryHeader';
import { RECONCILIATION_VIEW } from '../types/reports.types';
import { getReconciliationSummaryViewType } from '../utils/common.utils';
import AdyenReconciliationSummaryView from '../views/AdyenReconciliationSummaryView';
import ReconciliationSummary from '../views/ReconciliationSummary';

const ReconciliationSummaryPage = () => {
  const { restaurant, isChangingRestaurant } = useAppSelector(selectAuth);

  const viewType = getReconciliationSummaryViewType({
    restaurant,
    isLoading: isChangingRestaurant,
  });

  if (viewType === RECONCILIATION_VIEW.ADYEN_PAYMENTS) {
    return (
      <ReportsLayout
        HeaderComponent={AdyenReconciliationSummaryHeader}
        FilterComponent={AdyenReconciliationSummaryFilters}
        ContentComponent={AdyenReconciliationSummaryView}
        FooterComponent={PaymentsReportLastUpdateAtFooter}
      />
    );
  } else {
    return (
      <ReportsLayout
        HeaderComponent={ReconciliationSummaryHeader}
        FilterComponent={ReconciliationSummaryFilters}
        ContentComponent={ReconciliationSummary}
        FooterComponent={PaymentsReportLastUpdateAtFooter}
      />
    );
  }
};

export default ReconciliationSummaryPage;
