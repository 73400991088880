import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { useAppSelector } from '../../../hooks';
import { selectApp } from '../../../redux/selectors/appSelectors';
import { selectAuth } from '../../../redux/selectors/authSelectors';
import { Colors } from '../../../theme/colors';

dayjs.extend(utc);
dayjs.extend(tz);

const PaymentsReportLastUpdateAtFooter = () => {
  const { paymentsReportLastUpdated } = useAppSelector(selectApp);

  const { restaurant } = useAppSelector(selectAuth);

  const timeZone = restaurant?.timeZoneValue ? restaurant?.timeZoneValue : '';

  return (
    <Box sx={{ ml: 1 }}>
      <Typography
        variant="body2"
        sx={{ fontSize: 12, color: Colors.fontColorSecondary }}>
        {paymentsReportLastUpdated
          ? `Last updated on ${
              timeZone
                ? dayjs(paymentsReportLastUpdated)
                    .utcOffset(timeZone)
                    .format('DD-MM-YYYY hh:mm A')
                : dayjs(paymentsReportLastUpdated).format('DD-MM-YYYY hh:mm A')
            }`
          : null}
      </Typography>
    </Box>
  );
};

export default PaymentsReportLastUpdateAtFooter;
