import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import InputAdornment from '@mui/material/InputAdornment';
import Select, { SelectProps } from '@mui/material/Select';

import { SxProps, Theme } from '@mui/material/styles';
import { TextFieldVariants } from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { Colors } from '../theme/colors';

type AppSelectorProps = {
  icon?: React.ReactNode;
  labelRightComponent?: React.ReactNode;
  onClickIcon?: () => void;
  iconDisabled?: boolean;
  chip?: boolean;
  label?: string;
  variant?: TextFieldVariants;
  error?: string;
  showOptional?: boolean;
  iconPosition?: 'start' | 'end';
  textSx?: SxProps<Theme>;
  selectRef?: React.RefObject<HTMLDivElement>;
  renderSelectedArray?: (selected: string[]) => React.ReactNode;
} & Omit<SelectProps, 'error'>;

const AppSelector = ({
  icon,
  iconDisabled,
  onClickIcon,
  label,
  labelRightComponent,
  error,
  iconPosition = 'end',
  sx = {},
  textSx = {},
  showOptional,
  children,
  disabled,
  chip,
  selectRef,
  renderSelectedArray,
  MenuProps,
  ...props
}: AppSelectorProps) => {
  return (
    <Box
      sx={{
        width: '100%',
        ...sx,
      }}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '5px',
        }}>
        {label && (
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '14px',
              display: 'flex',
              alignItems: 'center',
              opacity: disabled ? 0.5 : 1,
            }}>
            {label}
            {showOptional ? (
              <span
                style={{
                  fontStyle: 'italic',
                  marginLeft: '4px',
                  marginRight: '4px',
                  fontWeight: 400,
                }}>
                - Optional
              </span>
            ) : null}
          </Typography>
        )}
        {labelRightComponent}
      </Box>

      <Select
        {...props}
        inputRef={selectRef}
        disabled={disabled}
        error={!!error}
        sx={{
          width: '100%',
          '& fieldset': {
            borderRadius: chip ? '100px' : '6px',
            minHeight: 0,
            maxHeight: chip ? 40 : 'none',
          },
          '& .MuiInputBase-input': {
            height: '1em',
            lineHeight: chip ? '0px' : '16px',
          },
          '& .MuiSelect-select': {
            minHeight: '0 !important',
          },
          ...textSx,
        }}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
          ...MenuProps,
        }}
        inputProps={
          icon
            ? iconPosition === 'start'
              ? {
                  startAdornment: (
                    <InputAdornment
                      position={iconPosition}
                      onClick={onClickIcon}
                      disablePointerEvents={iconDisabled}>
                      {icon}
                    </InputAdornment>
                  ),
                }
              : iconPosition === 'end'
                ? {
                    endAdornment: (
                      <InputAdornment
                        position={iconPosition}
                        onClick={onClickIcon}
                        disablePointerEvents={iconDisabled}>
                        {icon}
                      </InputAdornment>
                    ),
                  }
                : {}
            : undefined
        }
        renderValue={
          renderSelectedArray as
            | ((value: unknown) => React.ReactNode)
            | undefined
        }>
        {children}
      </Select>

      <Box
        sx={{
          height: '25px',
          marginTop: '-2px',
        }}>
        <Fade in={!!error}>
          {error ? (
            <Typography
              variant="caption"
              sx={{
                color: Colors.error,
                marginLeft: '10px',
                paddingTop: '5px',
                display: 'flex',
                lineHeight: 'normal',
              }}>
              {error}
            </Typography>
          ) : (
            <Box />
          )}
        </Fade>
      </Box>
    </Box>
  );
};

export default AppSelector;
