import {
  AddCircleOutlined as AddCircleOutlinedIcon,
  Cancel as CancelIcon,
} from '@mui/icons-material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import { useEffect, useState } from 'react';

import { Colors } from '../theme/colors';
import AppButton from './AppButton';
import { FILTER_TYPE } from './AppTable/types/table.types';

interface AppFilterTypes {
  chipHeader?: string;
  data: { label: string; value: string; icon?: JSX.Element }[];
  value: string[];
  onSelect: (value: string[]) => void;
  type: FILTER_TYPE;
  title: string;
  disabled?: boolean;
  anchorLeft?: boolean;
}

const AppFilterChip = ({
  chipHeader,
  data,
  value,
  onSelect,
  type,
  title,
  disabled,
  anchorLeft,
}: AppFilterTypes) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement | SVGSVGElement>(
    null,
  );

  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  const handleMenuItemClick = (name: string) => {
    if (type === FILTER_TYPE.CHECKBOX) {
      if (selectedValues.includes(name)) {
        setSelectedValues(selectedValues.filter((v) => v !== name));
      } else {
        setSelectedValues([...selectedValues, name]);
      }
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleIconClick = (event: React.MouseEvent<SVGSVGElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedValues(value);
  };

  const handleApply = () => {
    onSelect(selectedValues);
    setAnchorEl(null);
  };

  const handleReset = (
    event: React.MouseEvent<Element, MouseEvent> | undefined,
    name: string[],
  ) => {
    if (event) {
      event.stopPropagation();
    }
    setSelectedValues([]);
    onSelect(name);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    setSelectedValues(value);
  }, [value]);

  return (
    <div>
      {data.length === 1 || data.length === 0 ? (
        <></>
      ) : (
        <Button
          disabled={disabled}
          onClick={handleClick}
          sx={{
            color:
              value.length !== 0 || selectedValues.length !== 0
                ? Colors.primary
                : `var(--text-default,  ${Colors.fontColorSecondary} )`,
            border: `1px ${
              value.length !== 0 || selectedValues.length !== 0
                ? 'solid'
                : 'dashed'
            } ${
              value.length !== 0 || selectedValues.length !== 0
                ? Colors.primary
                : `var(--border-neutral, ${Colors.greyBorder} )`
            }`,
            borderRadius: '100px',
            fontSize: '12px',
            height: '24px',
            textTransform: 'none',
            paddingLeft: '5px',
            paddingRight: '5px',
            '&:hover': {
              border: `1px solid ${Colors.primary}`,
            },
          }}>
          {value.length !== 0 || selectedValues.length !== 0 ? (
            <CancelIcon
              sx={{
                fontSize: '14px',
                marginRight: '5px',
                '&:hover': {
                  color: Colors.primary,
                },
              }}
              data-testid="cancel-icon"
              onClick={(event) => handleReset(event, [])}
            />
          ) : (
            <AddCircleOutlinedIcon
              onClick={handleIconClick}
              sx={{
                fontSize: '14px',
                marginRight: '5px',
              }}
              data-testid="add-icon"
            />
          )}
          <Typography
            onClick={handleClick}
            sx={{ fontSize: '14px', fontWeight: 600 }}>
            {title}{' '}
            {value.length !== 0 || selectedValues.length !== 0
              ? `| ${(() => {
                  const selectedLabels = data
                    .filter(
                      (item) =>
                        value.includes(item.value) ||
                        selectedValues.includes(item.value),
                    )
                    .map((item) => item.label);

                  if (selectedLabels.length <= 2) {
                    return selectedLabels.join(', ');
                  } else {
                    const firstLabel = selectedLabels[0];
                    const extraLabelsCount = selectedLabels.length - 1;
                    return `${firstLabel} and ${extraLabelsCount} more`;
                  }
                })()}`
              : null}
          </Typography>
        </Button>
      )}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: anchorLeft ? 'left' : 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: anchorLeft ? 'left' : 'right',
        }}
        sx={{ marginTop: '5px' }}>
        <MenuList
          sx={{
            border: `1px solid var(--stroke-600, ${Colors.greyBorderLogin})`,
            borderRadius: '9px',
            paddingBottom: '0px',
            paddingTop: '0px',
            width: '226',
          }}>
          {chipHeader ? (
            <Box
              sx={{
                paddingLeft: type === FILTER_TYPE.SELECT ? '20px' : '10px',
                height: '38px',
                alignItems: 'center',
                display: 'flex',
                marginBottom: '5px',
              }}>
              <Typography sx={{ fontSize: '14px', fontWeight: 800 }}>
                {chipHeader}
              </Typography>
            </Box>
          ) : null}
          {data.map((item) => (
            <MenuItem
              onClick={() => handleMenuItemClick(item.value)}
              key={item.value}
              sx={{
                display: 'flex',
                padding: '0px',
                justifyContent:
                  type === FILTER_TYPE.SELECT ? 'space-between' : 'flex-start',
                fontSize: '12px',
                fontWeight: 500,
                height: type === FILTER_TYPE.SELECT ? '35px' : '30px',
                width: '214px',
                marginRight: '5px',
                marginLeft: '5px',
                marginBottom: '5px',
                marginTop: '5x',
                borderRadius: '10px',
              }}>
              {type === FILTER_TYPE.CHECKBOX ? (
                <Checkbox
                  checked={selectedValues.includes(item.value)}
                  color="primary"
                  sx={{ padding: '0', transform: 'scale(0.8)' }}
                />
              ) : null}
              <Typography
                sx={{ fontSize: '12px', fontWeight: 400, lineHeight: '20px' }}>
                {item.label}
              </Typography>
            </MenuItem>
          ))}
          {FILTER_TYPE.CHECKBOX ? (
            <Box sx={{ padding: '9px' }}>
              <AppButton
                onClick={handleApply}
                title="Apply"
                variant="contained"
                size="small"
                color="secondary"
                sx={{
                  color: Colors.white,
                  background: Colors.primary,
                  width: '100%',
                  '&:hover': {
                    backgroundColor: Colors.primary,
                  },
                }}
              />
            </Box>
          ) : null}
        </MenuList>
      </Popover>
    </div>
  );
};

export default AppFilterChip;
