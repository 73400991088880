import dayjs, { Dayjs } from 'dayjs';

export type RangePreset = {
  id:
    | 'today'
    | 'yesterday'
    | 'thisWeek'
    | 'lastWeek'
    | 'thisMonth'
    | 'lastMonth'
    | 'thisYear';
  label: string;
  value: [Dayjs, Dayjs];
};

export const rangePresets = [
  {
    id: 'today',
    label: 'Today',
    value: [dayjs.tz().startOf('day'), dayjs.tz().endOf('day')],
  },
  {
    id: 'yesterday',
    label: 'Yesterday',
    value: [
      dayjs.tz().subtract(1, 'd').startOf('day'),
      dayjs.tz().subtract(1, 'd').endOf('day'),
    ],
  },
  {
    id: 'thisWeek',
    label: 'This Week',
    value: [dayjs.tz().startOf('week'), dayjs.tz().endOf('day')],
  },
  {
    id: 'lastWeek',
    label: 'Last Week',
    value: [
      dayjs.tz().subtract(1, 'week').startOf('week'),
      dayjs.tz().subtract(1, 'week').endOf('week'),
    ],
  },
  {
    id: 'thisMonth',
    label: 'This Month',
    value: [dayjs.tz().startOf('month'), dayjs.tz().endOf('day')],
  },
  {
    id: 'lastMonth',
    label: 'Last Month',
    value: [
      dayjs.tz().subtract(1, 'month').startOf('month'),
      dayjs.tz().subtract(1, 'month').endOf('month'),
    ],
  },
  {
    id: 'thisYear',
    label: 'This Year',
    value: [dayjs.tz().startOf('year'), dayjs.tz().endOf('day')],
  },
].filter(Boolean) as RangePreset[];

export const formatDateTime = (date: Dayjs | null) =>
  date?.isValid() ? date.format('YYYY-MM-DD HH:mm:ss') : null;
