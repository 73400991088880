export interface FilterItems {
  label: string;
  value: string;
}
export interface RowType {
  cell: {
    getValue: () => void;
  };
  column: object;
  renderedCellValue: string;
  row: {
    original: {
      paymentMethod: string;
      status: string;
    };
  };
  table: object;
}

export interface IReconciliationSummaryResponseDTO {
  paymentType: string;
  collections: string;
  counts: string;
  trxPercentageFee: string;
  trxFixedFee: string;
  trxGrandTotal: string;
  refunds: string;
  balance: string;
  subRows?: IReconciliationSummaryResponseDTO[];
}

export interface IReconciliationSummaryTable {
  paymentType: string;
  collections: string;
  counts: string;
  trxPercentageFee: string;
  trxFixedFee: string;
  trxGrandTotal: string;
  refunds: string;
  balance: string;
  subRows: IReconciliationSummaryTable[];
}

export interface IOrderCountSummaryTable {
  orderDate: string;
  dineInCounts: string;
  pickUpCounts: string;
  totalOrders: string;
  deliveryCounts: string;
  otherPaymentsCounts: string;
}

export interface OrderCountSummaryDTO {
  orderDate: string;
  dineInCounts: string;
  pickUpCounts: string;
  totalOrders: string;
  deliveryCounts: string;
  otherPaymentsCounts: string;
}

export interface OrderCountSummaryResponseDTO {
  orderDate: string;
  dineInCounts: string;
  pickUpCounts: string;
  totalOrders: string;
  deliveryCounts: string;
  otherPaymentsCounts: string;
}

export interface ISalesByMenuItemSummaryTable {
  menuItem: string;
  qtySold: string;
  price: string;
  sales: string;
  category: string;
}

export interface ISalesByMenuItemSummaryResponseDTO {
  menuItem: string;
  qtySold: string;
  price: string;
  sales: string;
  category: string;
}

export interface SalesByMenuItemSummaryDTO {
  menuItem: string;
  qtySold: string;
  price: string;
  sales: string;
  category: string;
}

export interface TotalCounts {
  dineInCounts: number;
  pickUpCounts: number;
  totalCounts: number;
  deliveryCounts: number;
  nonEatMeCounts: number;
}

export interface TotalCountsForSalesByMenuItems {
  totalSales: string;
  totalItems: string;
}

export interface Categories {
  label: string;
  value: string;
}

export interface OrderCountSummaryExtrasDTO {
  totalCounts: TotalCounts;
  lastUpdated: string;
}

export interface SalesByMenuItemSummaryExtrasDTO {
  totalCounts: TotalCountsForSalesByMenuItems;
  categories: Categories[];
}

export interface ReconciliationSummaryExtrasDTO {
  firstPaymentDate: string;
  lastUpdated: string;
  products: FilterItems[];
  channels: FilterItems[];
}

export interface FormattedData {
  name: string;
  data: number[];
}

export interface HourlySalesSummaryDTO {
  hour_placed: string;
  date: string;
  totalEarnings: number;
}

export interface HourlySalesSummaryResponseDTO {
  hour_placed: string;
  date: string;
  totalEarnings: number;
}

export interface IHourlySalesSummaryTable {
  hour_placed: string;
  date: string;
  totalEarnings: number;
}

export interface HourlySalesSummaryExtrasDTO {
  avgSales: string;
  totalCollected: string;
  lastUpdated: string;
}
export interface IDailyStripeSummaryResponseDTO {
  date: string;
  count: string;
  collections: string;
  transactionFees: string;
  refunds: string;
  adjustments: string;
  payable: string;
}

export interface DailyStripeSummaryExtrasDTO {
  totalCount: number;
  lastUpdated: string;
}

export interface IDailyStripeSummaryTable {
  date: string;
  count: string;
  collections: string;
  transactionFees: string;
  refunds: string;
  adjustments: string;
  payable: string;
}

export enum RECONCILIATION_VIEW {
  ADYEN_PAYMENTS = 'ADYEN_PAYMENTS',
  LOADING = 'LOADING',
}
