import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import Box from '@mui/material/Box';
import { SxProps } from '@mui/material/styles';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';

import { SNACKBARTYPE, useSnackbar } from '../components/AppSnackbar';
import { useAppSelector } from '../hooks/useAppSelector';
import { selectAuth } from '../redux/selectors/authSelectors';
import { Colors } from '../theme/colors';
import { decimalCalculations } from '../utils';
import AppButton from './AppButton';
import { IChipCell } from './AppCells/ChipCell';
import { ICurrencyCell } from './AppCells/CurrencyCell';
import { IDateCell } from './AppCells/DateCell';
import { ITextCell } from './AppCells/TextCell';
import AppColumnSelectorPopup, { IColumn } from './AppColumnSelectorPopup';
import {
  APP_COLUMN_TYPES,
  IAppTableColumn,
  IAppTableRow,
} from './AppTable/types/table.types';

type AppExportButtonProps<T, U> = {
  fileName: string;
  columns: IAppTableColumn[];
  dataFetcher: () => Promise<T[]>;
  formatter: (data: T) => IAppTableRow<U>;
  disabled?: boolean;
  hideColumnSelector?: boolean;
  removeFormatter?: boolean;
  buttonSx?: SxProps;
  iconSx?: SxProps;
};

function AppExportButton<T, U>({
  fileName,
  columns,
  dataFetcher,
  formatter,
  disabled,
  hideColumnSelector,
  removeFormatter,
  buttonSx,
  iconSx,
}: Readonly<AppExportButtonProps<T, U>>) {
  const formattedColumns: IColumn[] = useMemo(
    () =>
      columns.map((column) => ({
        accessorKey: column.accessorKey,
        category: column.category,
        selected: column.visible,
        title: column.header,
        disabled: !column.visibilityChangeable,
      })),
    [columns],
  );

  const { openSnackbar } = useSnackbar();

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { restaurant } = useAppSelector(selectAuth);

  const timeZone = restaurant?.timeZoneValue ? restaurant?.timeZoneValue : '';

  const handleExport = async (newColumns: IColumn[]) => {
    const data = await dataFetcher();

    if (!data || data.length === 0) {
      openSnackbar(
        'We couldn’t find any data to export based on your selections!',
        SNACKBARTYPE.ERROR,
      );
      return;
    }

    // format columns
    const formattedExportColumns = columns.map((originalCol) => {
      const newCol = newColumns.find(
        (col) => col.accessorKey === originalCol.accessorKey,
      );

      return {
        header: originalCol.header,
        accessorKey: originalCol.accessorKey,
        exportable: !!newCol?.selected,
      };
    });

    // filter data
    const visibleKeys = formattedExportColumns
      .filter((item) => item.exportable)
      .map((item) => item.accessorKey);

    const headers = formattedExportColumns
      .filter((item) => item.exportable)
      .map((item) =>
        item.header.replace(/\w\S*/g, function (txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }),
      );

    const csvData = data.map((el) => {
      const resultItem: string[] = [];
      visibleKeys.forEach((key) => {
        let formattedData = '-';

        if (!removeFormatter) {
          const formattedRow = formatter(el) as unknown as {
            [key: string]: IChipCell | ICurrencyCell | ITextCell | IDateCell;
          };

          const item = formattedRow[key];

          switch (item.type) {
            case APP_COLUMN_TYPES.DATE:
              formattedData = dayjs(item.data.value).isValid()
                ? dayjs(item.data.value)
                    .utcOffset(timeZone)
                    .format('YYYY-MM-DD hh:mm A')
                : item.data.value;
              break;

            case APP_COLUMN_TYPES.TEXT:
              formattedData = item.data.value?.toString() ?? '';
              break;

            case APP_COLUMN_TYPES.CHIP:
              formattedData = item.data.value;
              break;

            case APP_COLUMN_TYPES.CURRENCY: {
              const value = String(item.data.value)?.replace(/,/g, '');
              const formattedValueWithDecimals = item.data.isCent
                ? decimalCalculations(value).toDecimal().toMoney('', true)
                : decimalCalculations(value).toMoney('', true);
              formattedData = formattedValueWithDecimals;
              break;
            }
          }
        } else {
          formattedData =
            (el as { [key: string]: unknown })[key]?.toString() ?? '';
        }

        resultItem.push(formattedData);
      });

      return resultItem.join(',');
    });

    csvData.unshift(headers.join(','));

    const csvContent = `data:text/csv;charset=utf-8,${csvData.join('\n')}`;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute(
      'download',
      `${fileName}_${dayjs.tz().format('DDMMYY_hhmm')}.csv`,
    );
    document.body.appendChild(link);
    link.click();

    setIsPopupOpen(false);
  };

  const handleExportAll = () => {
    const newColumns = formattedColumns.map((col) => ({
      ...col,
      selected: col.disabled ? col.selected : false,
    }));

    handleExport(newColumns);
  };

  return (
    <Box>
      <AppButton
        title="Export"
        onClick={() =>
          hideColumnSelector ? handleExportAll() : setIsPopupOpen(true)
        }
        startIcon={<ArrowOutwardIcon sx={{ ...iconSx }} />}
        variant="outlined"
        size="small"
        color="secondary"
        disabled={disabled}
        sx={{
          height: 39,
          backgroundColor: Colors.white,
          '&:hover': {
            outline: `1px solid ${Colors.primary}`,
            backgroundColor: Colors.white,
          },
          border: `1px solid ${Colors.greyBorder}`,
          color: Colors.black,
          ...buttonSx,
        }}
      />

      <AppColumnSelectorPopup
        open={isPopupOpen}
        columns={formattedColumns}
        title="Export"
        buttonTitle="Export"
        onClose={() => setIsPopupOpen(false)}
        onSave={handleExport}
      />
    </Box>
  );
}

export default AppExportButton;
