import Box from '@mui/material/Box';
import { useCallback, useEffect, useMemo } from 'react';

import AppTable from '../../../components/AppTable';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { updatePaymentsReportLastUpdated } from '../../../redux/AppSlice';
import { selectApp } from '../../../redux/selectors/appSelectors';
import { selectAuth } from '../../../redux/selectors/authSelectors';
import { selectReconciliationSummary } from '../../../redux/selectors/reconciliationSummarySelectors';
import ReportsEmptyTable from '../components/ReportsEmptyTable';
import { fetchReconciliationSummaryDetails } from '../redux/reconciliationSummarySlices';
import { getFormattedReconciliationSummary } from '../utils/formatting.utils';

const ReconciliationSummary = () => {
  const dispatch = useAppDispatch();

  const {
    reconciliationSummaryData,
    columns,
    refreshing,
    selectedProducts,
    selectedChannels,
    lastUpdated,
  } = useAppSelector(selectReconciliationSummary);

  const { reportStartDate, reportEndDate } = useAppSelector(selectApp);

  const { restaurant } = useAppSelector(selectAuth);

  const getData = useCallback(async () => {
    // TODO : country code
    await dispatch(
      fetchReconciliationSummaryDetails({
        country: 'SG',
        date_from: reportStartDate ?? undefined,
        date_to: reportEndDate ?? undefined,
        product: selectedProducts.join(','),
        channel: selectedChannels.join(','),
        offset: restaurant?.timeZoneValue,
      }),
    );
  }, [
    dispatch,
    reportEndDate,
    reportStartDate,
    selectedProducts,
    selectedChannels,
    restaurant?.timeZoneValue,
  ]);

  useEffect(() => {
    dispatch(updatePaymentsReportLastUpdated(lastUpdated));
  }, [dispatch, lastUpdated]);

  useEffect(() => {
    getData();
  }, [dispatch, getData]);

  const formattedData = useMemo(() => {
    return reconciliationSummaryData.map(getFormattedReconciliationSummary);
  }, [reconciliationSummaryData]);

  return (
    <Box
      sx={{
        mb: 5,
      }}
      data-testid="Payments Reconciliation Summary">
      <AppTable
        columns={columns}
        rows={formattedData}
        refreshing={refreshing}
        emptyComponent={<ReportsEmptyTable title={'No records found'} />}
        enablePagination={false}
        enableExpanding={true}
        enableExpandAll={true}
      />
    </Box>
  );
};

export default ReconciliationSummary;
