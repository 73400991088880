import Box from '@mui/material/Box';

import AppFilterChip from '../../../components/AppFilterChip';
import { FILTER_TYPE } from '../../../components/AppTable/types/table.types';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
  setChannels,
  setProducts,
} from '../redux/adyenReconciliationSummarySlice';
import { selectAdyenReconciliationSummary } from '../../../redux/selectors/adyenReconciliationSummarySelectors';

const AdyenReconciliationSummaryFilters = () => {
  const dispatch = useAppDispatch();

  const { products, channels, selectedProducts, selectedChannels } =
    useAppSelector(selectAdyenReconciliationSummary);

  const handleProductClick = (value: string[]) => {
    dispatch(setProducts(value));
  };

  const handleChannelClick = (value: string[]) => {
    dispatch(setChannels(value));
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', my: 2 }}>
      <AppFilterChip
        chipHeader="Filter by Products"
        title={'Products'}
        data={products ?? []}
        value={selectedProducts}
        onSelect={handleProductClick}
        type={FILTER_TYPE.CHECKBOX}
        disabled={products ? false : true}
        anchorLeft
      />
      <Box sx={{ ml: 1.2 }} />
      <AppFilterChip
        chipHeader="Filter by Channels"
        title={'Channels'}
        data={channels ?? []}
        value={selectedChannels}
        onSelect={handleChannelClick}
        type={FILTER_TYPE.CHECKBOX}
        disabled={channels ? false : true}
        anchorLeft
      />
    </Box>
  );
};

export default AdyenReconciliationSummaryFilters;
