import { BasePaginationExtras } from '../../../types/api.types';
import { IBaseEntity } from '../../menu/types/common.types';

export const FILTER_TYPES = {
  HOURLY: 'hourly',
  DAILY: 'daily',
  DAY_OF_THE_WEEK: 'day_of_the_week',
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
  YEARLY: 'yearly',
  CUSTOM: 'custom',
} as const;

export type SalesSummaryFilterType =
  (typeof FILTER_TYPES)[keyof typeof FILTER_TYPES];

export interface ReportsSalesSummaryState {
  salesSummaryStatus: ISalesSummaryStatusResponseDTO;
  salesSummaryGraphData: SalesSummaryResponseDTO[];
  salesSummaryTableData: SalesSummaryResponseDTO[];
  refreshing: boolean;
  refreshingGraph: boolean;
  refreshingStatus: boolean;
  activeTab: string[];
  selectedFilterType?: SalesSummaryFilterType;
  extras: SalesSummaryExtras & {
    date_from?: Date;
    date_to?: Date;
    employeeIds?: string;
  };
}

export interface SalesSummaryResponseDTO extends IBaseEntity {
  date: string;
  data: {
    voided: number;
    delivery: number;
    takeAway: number;
    dineIn: number;
    online: number;
    KIOSK: number;
    POS: number;
    refunded: number;
    succeeded: number;
    totalNumberOfOrders: number;
    grossSales: number;
    discounts: number;
    refunds: number;
    netSales: number;
  };
}

export interface ISalesSummaryTable extends Record<string, unknown> {
  date: string;
  grossSales: number;
  discounts: number;
  refunds: number;
  netSales: number;
}

export interface GetAllSalesSummaryQueryDTO {
  status?: string;
  sort_order?: string;
  sort_by?: string;
  limit?: number;
  skip?: number;
  search_key?: string;
  date_from?: string;
  date_to?: string;
  employeeId?: number;
}

export interface SalesSummaryParams {
  date_from?: string;
  date_to?: string;
  status?: string;
  limit?: number;
  skip?: number;
  employeeIds?: string;
  offset?: string;
  filter_by?: string;
}

export interface ISalesSummaryStatusResponseDTO {
  showLoadingScreen: boolean;
}

export interface SalesSummaryExtras extends BasePaginationExtras {
  employeeIds: string | undefined;
  lastUpdated?: Date;
  totalSalesDetails?: TotalSalesDetails[];
}

export interface TotalSalesDetails {
  totalGrossSales?: number;
  totalDiscounts?: number | null;
  totalRefunds?: number | null;
  totalNetSales?: number;
}

export interface TabItem {
  label: string;
  value: string;
}

export const CardTypes = {
  GROSS_SALES: 'grossSales',
  DISCOUNTS: 'discounts',
  REFUNDS: 'refunds',
  NET_SALES: 'netSales',
};

export const CardTypesDisplay = {
  [CardTypes.GROSS_SALES]: 'Gross Sales',
  [CardTypes.DISCOUNTS]: 'Discounts',
  [CardTypes.REFUNDS]: 'Refunds',
  [CardTypes.NET_SALES]: 'Net Sales',
};
