import Box from '@mui/material/Box';
import dayjs from 'dayjs';
import debounce from 'lodash/debounce';
import { useMemo } from 'react';

import AppDateTimePicker from '../../../components/AppDateTimeRangePicker';
import AppExportButton from '../../../components/AppExportButton';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { setReportDates } from '../../../redux/AppSlice';
import { selectApp } from '../../../redux/selectors/appSelectors';
import { selectReconciliationSummary } from '../../../redux/selectors/reconciliationSummarySelectors';
import { Colors } from '../../../theme/colors';
import { PaginatedResponseDTO } from '../../../types/api.types';
import { fetchReconciliationSummaryExport } from '../redux/reconciliationSummarySlices';
import {
  IReconciliationSummaryResponseDTO,
  IReconciliationSummaryTable,
} from '../types/reports.types';
import { getFormattedReconciliationSummaryExport } from '../utils/formatting.utils';
import { selectAuth } from '../../../redux/selectors/authSelectors';

const ReportsHeader = () => {
  const dispatch = useAppDispatch();

  const { columns, selectedProducts, selectedChannels, refreshing } =
    useAppSelector(selectReconciliationSummary);

  const { reportStartDate, reportEndDate } = useAppSelector(selectApp);

  const { restaurant } = useAppSelector(selectAuth);

  const onCalendarSave = (start: string | null, end: string | null) => {
    dispatch(
      setReportDates({
        reportStartDate: start ?? undefined,
        reportEndDate: end ?? undefined,
      }),
    );
  };

  const debouncedFetchExportData = useMemo(
    () =>
      debounce(
        async () => {
          const response = await dispatch(
            fetchReconciliationSummaryExport({
              country: 'SG',
              date_from: reportStartDate,
              date_to: reportEndDate,
              product: selectedProducts.join(','),
              channel: selectedChannels.join(','),
              is_export: 'export',
              offset: restaurant?.timeZoneValue,
            }),
          );

          let results: IReconciliationSummaryResponseDTO[] = [];

          if (response.meta.requestStatus === 'fulfilled' && response.payload) {
            const payload = response.payload as PaginatedResponseDTO<
              IReconciliationSummaryResponseDTO,
              never
            >;
            results = payload.results;
          }
          return results;
        },
        500,
        { leading: true, trailing: false },
      ),
    [
      dispatch,
      reportEndDate,
      reportStartDate,
      restaurant?.timeZoneValue,
      selectedChannels,
      selectedProducts,
    ],
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        mb: 2,
        border: `1px solid ${Colors.backgroundShade}`,
        backgroundColor: Colors.backgroundSecondary,
      }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', p: 1.2 }}>
        <AppDateTimePicker
          defaultValues={[dayjs.tz(reportStartDate), dayjs.tz(reportEndDate)]}
          disabled={refreshing}
          onSave={onCalendarSave}
        />

        <Box sx={{ ml: 1.2 }} />
        <AppExportButton<
          IReconciliationSummaryResponseDTO,
          IReconciliationSummaryTable
        >
          fileName={`paymentreconciliation`}
          columns={columns}
          dataFetcher={debouncedFetchExportData}
          formatter={getFormattedReconciliationSummaryExport}
          hideColumnSelector={true}
          buttonSx={{ height: 30, fontSize: '12px' }}
          iconSx={{ width: 14 }}
        />
      </Box>
    </Box>
  );
};

export default ReportsHeader;
